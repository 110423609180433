.root {
  position: relative;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 5px;
  padding: 6px 8px;
  @media (max-width: 767px) {
    padding: 3px 6px;
  }
  & svg {
    @media (max-width: 767px) {
      height: 8px;
      width: 10px;
    }
  }
}

.filterHeader {
  line-height: 24px;
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #3c3c3c;
  margin-bottom: 14px;
  margin-top: 0;
  padding: 0;
}

.label {
  color: var(--matterColor);
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.labelSelected {
  color: var(--marketplaceColor);
  white-space: nowrap;
}

.labelButton {
  /* Override button styles */
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.openSign {
  float: right;
  margin-left: 5px;
}

.formWrapper {
  padding-left: 12px;
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}

.plain {
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: var(--zIndexPopup);
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  padding: 20px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
