@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 0px;
  padding-bottom: 6px;
  border: none;
  padding-right: 0;

  @media (--viewportLarge) {
    padding: 4px 0 4px 0px;
  }
}

.list {
  margin: 0;

  @media (--viewportMedium) {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background: #edf5fa;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--marketplaceColorDark);
      border-radius: 6px;
      outline: 1px solid #fff;
    }
  }
}

.item {
  padding: 2px 0;
  margin-bottom: 4px;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  @media (max-width: 767px) {
    padding: 12px 19px;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    color: #000;
    background: #f1f0f0;
    border-radius: 5px;

    & span {
      height: auto;
      line-height: 20px;
      color: #000;
      font-weight: 800;
    }
  }
  & label {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
