@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 5px;
  padding: 6px 8px;
  @media (max-width: 767px) {
    padding: 6px 6px;
    margin-bottom: 0px;
    border: 0;
    border-radius: 0;
    border-top: 1px solid #8e8e8e;
  }
  & svg {
    @media (max-width: 767px) {
      height: 8px;
      width: 10px;
    }
  }
}

.categoryIconMobile {
  & svg {
    width: 19px;
    height: 10px;
  }
  @media (--viewportMedium) {
    display: none;
  }
}
.arrowDown {
  transform: rotate(180deg);
}

.filterHeader {
  line-height: 24px;
  @media (max-width: 767px) {
    & .labelButton {
      display: flex;
      line-height: 33px;
      justify-content: space-between;
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #3c3c3c;
  margin-bottom: 14px;
  margin-top: 0;
  padding: 0;
}

.label {
  color: var(--matterColor);
  display: flex;
  gap: 3px;
  margin: 0;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.labelSelected {
  color: var(--marketplaceColor);
  white-space: nowrap;
  margin: 0;
  @media (max-width: 767px) {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
    white-space: nowrap;
    display: inline-block;
    margin: 0;
  }
}

.labelButton {
  /* Override button styles */
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.labelButtonContent {
  display: inline-block;
  /* width: 100%; */

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.openSign {
  float: right;
  margin-left: 5px;
}

.formWrapper {
  padding-left: 12px;
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}

.plain {
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: var(--zIndexPopup);
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  padding: 20px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

/* .modalSearchFilter{
  background-color: transparent !important;
  & > div {
    background-color: transparent !important;
    & > div {
      box-shadow: 1px 3px 11px 4px #c5c5c5;
    }
  }
} */
