.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 0;
  @media (max-width: 767px) {
    margin-top: 8px;
    padding-top: 8px;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}
.brandInput {
  position: relative;
}

.searchMenuIcon {
  /* margin-left: 10px; */
}

.searchContainer {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.searchContainer input {
  border: none;
}

.searchContainer button {
  border: none;
  /* cursor: pointer; */
}

.clearSearchButton {
  /* border: 1px solid black;
  border-radius: 50%; */
}

.brandDropDown {
  background-color: #ffff;
  border: 0.5px solid black;
  box-shadow: 3px 3px 15px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  padding: 0 10px 0 0;

  & li {
    padding: 0 10px 0 10px;
    border-bottom: 1px solid #dddd;
    margin-right: 10px;

    &:hover {
      color: var(--marketplaceColorDark);
    }
  }
}

.searchResults_show {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColorDark);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
