@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  /* border-bottom: 1px solid rgba(82, 89, 97, 0.4); */
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 5px;
  padding: 6px 8px;
  @media (max-width: 767px) {
    padding: 6px 6px;
    margin-bottom: 0px;
    border: 0;
    border-radius: 0;
    border-top: 1px solid #8e8e8e;
  }
  & svg {
    @media (max-width: 767px) {
      height: 8px;
      width: 10px;
    }
  }
}
.searchFiltersMobileList {
  padding: 0px 30px 9px;

  @media (--viewportMedium) {
    display: none;
  }
}
.categoryIconMobile {
  & svg {
    width: 19px;
    height: 10px;
  }
  @media (--viewportMedium) {
    display: none;
  }
  /* @media (max-width: 767px) {
    display: none;
  } */
}
.list {
  margin: 0;
  max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.text {
  color: var(--marketplaceColorDark);
}
.item {
  padding: 2px 0;
  margin-bottom: 1px;
  margin-left: 0px;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
  @media (max-width: 767px) {
    padding: 12px 19px;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    color: #000;
    background: #f1f0f0;
    border-radius: 5px;
  }

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  & label {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.arrowDown {
  transform: rotate(180deg);
}

.filterHeader {
  line-height: 33px;
  white-space: nowrap;
  @media (max-width: 767px) {
    & .labelButton {
      display: flex;
      line-height: 33px;
      justify-content: space-between;
    }
  }
}

.dropDownHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 34px;
  & .dropdownLabel {
  }
  & .dropDownReset {
    font-weight: var(--fontWeightMedium);
    color: var(--marketplaceColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    text-decoration-line: underline;
    display: inline;
    margin: 0px;
    padding: 0;
    outline: none;
    text-align: left;
    cursor: pointer;
  }
}

.labelButton {
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  /* width: 100%; */

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  /* max-width: calc(100% - 16px); */
}

.label,
.labelSelected {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #3c3c3c;
  margin-bottom: 14px;
  margin-top: 0;
  padding: 0;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.labelSelected {
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  white-space: nowrap;
  display: inline-block;
  margin: 0;
  font-size: 16px;
  @media (max-width: 767px) {
    width: auto;
  }
}

.label {
  color: var(--matterColor);
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
}

.labelSelected {
  color: var(--matterColor);
  margin-left: 5px;
}

.openSign {
  float: right;
  margin-left: 5px;
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 0px;
    margin-top: 8px;
  }
}

.plain {
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: var(--zIndexPopup);
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  left: 0;
  right: 0;
}

.subcategories {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 8px;
}
.customselect {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* fill: #fff;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.3));
  background: #fff;
  padding: 10px 40px 28px;
  border-radius: 10px; */
  @media (max-width: 767px) {
    padding: 10px 15px 28px;
  }
  & .customList {
    @media (--viewportMedium) {
      max-height: 488px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background: #edf5fa;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--marketplaceColorDark);
        border-radius: 6px;
        outline: 1px solid #fff;
      }
    }
  }
}
.text1 {
  color: red;
}
.category {
  position: relative;
  margin-right: 25px;
  & > span {
    z-index: 1;
    position: relative;
    color: var(--marketplaceColorDark);
    cursor: pointer;
    display: block;
    color: #4a4a4a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 206.25% */
  }
  & .categoryIcon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
  &:hover {
    & > span {
      font-weight: 800;
    }
  }
  &.active {
    font-weight: 800;

    & .categoryIcon {
      transform: rotate(180deg);
    }
  }
}

.subcategory {
  color: #8e8e8e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  position: relative;
  & > span {
    cursor: pointer;
    display: block;
  }
  & .subCategorycategoryIcon {
    position: absolute;
    /* right: 10px; */
    left: -20px;
    top: 0;
  }
}

.filterMangePage {
  padding: 0px 0px 9px !important;
}

.modalCategory {
  & > div {
    & > div {
      & button {
        top: 21px !important;
        right: 0;
        @media (--viewportMedium) {
          right: 14px;
        }
        & svg {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }
}

.filterPlainDiv {
  padding: 0 22px 30px 0;
  max-height: calc(var(--vh) * 100 - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-right: 16px;
  margin-top: 20px; */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColorDark);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}
